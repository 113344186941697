import { useTranslation } from "react-i18next";
// config
import { allLangs, defaultLang } from "~/config";

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t } = useTranslation();
  const i18nLang = i18n.language;
  const langStorage = localStorage.getItem("i18nextLng") || i18nLang;

  const currentLang =
    allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = async (newlang: string): Promise<void> => {
    localStorage.setItem("i18nextLng", newlang);
    await i18n.changeLanguage(newlang);
    document.dir = newlang === "ar" ? "rtl" : "ltr";
  };

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLangs,
  };
}
