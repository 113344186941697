import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  PopoverGroup,
} from "@headlessui/react";
import LanguageDropdown from "~components/LanguageDropdown";
import FWS from "~components/logos/FWS";
import useLocales from "~hooks/useLocales";
import { useState } from "react";

const Header = () => {
  const { t } = useLocales();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="sticky inset-x-0 top-4 z-50 flex w-full flex-wrap lg:flex-nowrap lg:justify-start">
      <nav
        className="relative mx-2 w-full max-w-[66rem] rounded-lg bg-neutral-800 px-5 py-2 lg:mx-auto lg:flex lg:items-center lg:justify-between lg:py-0"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          {/* <!-- Logo --> */}
          <a
            className="inline-block flex-none rounded-md text-xl font-semibold focus:opacity-80 focus:outline-none"
            href="#home"
            aria-label="FWS"
          >
            <FWS className="h-16 text-[#ff0] lg:h-8" />
          </a>
          {/* <!-- End Logo --> */}

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-10 w-10" aria-hidden="true" />
            </button>
          </div>
        </div>

        {/* <!-- Collapse --> */}
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <div className="mt-5 flex flex-col gap-x-0 gap-y-4 lg:mt-0 lg:flex-row lg:items-center lg:justify-end lg:gap-x-7 lg:gap-y-0 lg:ps-7">
            <a
              className="text-sm text-white hover:text-neutral-300 focus:text-neutral-300 focus:outline-none lg:py-4"
              href="#home"
              aria-current="page"
            >
              {t("home")}
            </a>
            <a
              className="text-sm text-white hover:text-neutral-300 focus:text-neutral-300 focus:outline-none lg:py-4"
              href="#features"
            >
              {t("tech.stack")}
            </a>
            <a
              className="text-sm text-white hover:text-neutral-300 focus:text-neutral-300 focus:outline-none lg:py-4"
              href="#stats"
            >
              {t("stats")}
            </a>
            <a
              className="text-sm text-white hover:text-neutral-300 focus:text-neutral-300 focus:outline-none lg:py-4"
              href="#approach"
            >
              {t("approach")}
            </a>

            <div>
              <a
                className="group inline-flex items-center gap-x-2 rounded-full bg-[#ff0] px-3 py-2 text-sm font-medium text-neutral-800 focus:outline-none"
                href="#contact-us"
              >
                {t("contact.us")}
              </a>
            </div>
            <div className="max-w-32">
              <LanguageDropdown />
            </div>
          </div>
        </PopoverGroup>
        {/* <!-- End Collapse --> */}
      </nav>
      <Dialog
        className="relative z-50 flex w-screen lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-white/30 duration-300 ease-out data-[closed]:opacity-0"
        />
        <DialogPanel
          transition
          className="overflow-none fixed bottom-2 left-2 right-2 top-4 rounded-xl border  border-gray-600 bg-neutral-800 px-5 py-2 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
        >
          <div className="flex items-center justify-between">
            <a href="#home" className="-m-1.5 p-1.5">
              <span className="sr-only">{t("layout.company")}</span>
              <FWS className="h-16 text-[#ff0] lg:h-8" />
            </a>

            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5  text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-10 w-10" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <CloseButton
                  as={"a"}
                  href={"#home"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-neutral-300 focus:text-neutral-300 focus:outline-none lg:py-4"
                >
                  {t("home")}
                </CloseButton>

                <CloseButton
                  as={"a"}
                  href={"#features"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-neutral-300 focus:text-neutral-300 focus:outline-none lg:py-4"
                >
                  {t("tech.stack")}
                </CloseButton>

                <CloseButton
                  as={"a"}
                  href="#stats"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-neutral-300 focus:text-neutral-300 focus:outline-none md:py-4"
                >
                  {t("stats")}
                </CloseButton>
                <CloseButton
                  as={"a"}
                  href="#approach"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-neutral-300 focus:text-neutral-300 focus:outline-none md:py-4"
                >
                  {t("approach")}
                </CloseButton>
                <CloseButton
                  as={"a"}
                  href="#contact-us"
                  className=" block items-center gap-x-2 rounded-full bg-[#ff0] px-3 py-2 text-base font-semibold leading-7 text-neutral-800 focus:outline-none"
                >
                  {t("contact.us")}
                </CloseButton>

                <hr className="mb-2 border-dashed border-gray-800 dark:border-white" />
                <LanguageDropdown />
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default Header;
