import { Transition } from "@headlessui/react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

import useLocales from "~hooks/useLocales";

export default function LanguageDropdown() {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const handleChangeLang = (newLang: typeof currentLang) => {
    void onChangeLang(newLang.value);
  };
  return (
    <Listbox value={currentLang} onChange={handleChangeLang}>
      <ListboxButton
        className={
          "relative block rounded-lg py-1.5 pl-2 pr-2 text-left text-sm/6 text-white focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
        }
      >
        <img
          src={currentLang.icon}
          alt={currentLang.value}
          className="inline w-5"
        />
      </ListboxButton>
      <Transition
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ListboxOptions
          anchor="bottom"
          className="z-50 rounded-xl border border-white/5 bg-neutral-900  [--anchor-gap:var(--spacing-1)] focus:outline-none"
        >
          {allLangs.map((lang) => (
            <ListboxOption
              key={lang.name}
              value={lang}
              className={`${lang.id === currentLang.id ? "text-sky-500" : ""} group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10`}
            >
              <img
                src={lang.icon}
                alt={lang.value}
                className="mr-2 inline w-5"
              />
              <div className="text-sm/6 text-white">{lang.name}</div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Transition>
    </Listbox>
  );
}
