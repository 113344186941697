export enum Lang {
  ar = "ar",
  en = "en-US",
  fr = "fr-FR",
}

export const translations: Record<Lang, object> = {
  [Lang.ar]: {
    home: "الصفحة الرئيسية",
    "layout.company": "Frontier Web Services",
    "layout.copyright": "© {{date}} Frontier Web Services",
    logout: "تسجيل خروج",
    "errors.general": "آسف، كان هناك خطأ!",
    "errors.required.fields": "الرجاء إكمال الحقول المطلوبة!",
    save: "حفظ",
    back: "العودة",
    download: "تحميل",
    delete: "حذف",
    cancel: "إلغاء",
    ok: "موافق",

    search: "بحث",

    "show.more": "عرض المزيد",

    "click.to.download": "اضغط للتحميل",

    "last.name": "اسم العائلة",
    "first.name": "الاسم",
    "full.name": "الاسم الكامل",

    "all.fields.required": "جميع الحقول مطلوبة.",

    NetworkError: "عذرا ، كان هناك خطأ في الاتصال ، يرجى المحاولة مرة أخرى.",
    "see.more": "شاهد المزيد",
    UNCONFIRMED: "غير مؤكد",
    CONFIRMED: "مؤكد",

    logo: "شعار",
    "ERROR_auth/network-request-failed": "فشل طلب الشبكة",
    "ERROR_auth/too-many-requests":
      "تم تعطيل الوصول إلى هذا الحساب مؤقتًا بسبب العديد من محاولات تسجيل الدخول الفاشلة. يمكنك استعادته فورًا عن طريق إعادة تعيين كلمة المرور الخاصة بك أو يمكنك المحاولة مرة أخرى لاحقًا.",
    refresh: "تحديث",
    "update.available": "يتوفر تحديث جديد للتطبيق",
    "update.available.desc":
      "اضغط على `تحديث` للحصول على آخر تحديث ، أو انقر فوق `إلغاء` لتجاهل هذا التحديث.",
    active: "نشط",

    inactive: "غير نشط",
    update: "تحديث",

    "error.code.404": "404",
    "error.message.404": "عذرا حدث خطأ ما",
    "error.message.404.details": "عذرًا ، لم نتمكن من العثور على صفحتك.",
    "common.back.to.home": "العودة إلى الصفحة الرئيسية",
    "error.message.403": "ممنوع",
    "error.message.403.details": "ليس لديك إذن للوصول إلى هذا المورد.",
    "error.message.500": "خطأ داخلي في الخادم",
    "error.message.500.details":
      "واجه الخادم خطأ غير متوقع. حاول مرة أخرى لاحقا.",

    "no.results": "عذرا لم يتم العثور على نتائج!",
    "no.results.action": "حاول تعديل معايير البحث الخاصة بك.",
    "release.date": "إصدار {{date}}",
    "tech.stack": "التكنولوجيا المستخدمة",
    stats: "إحصائيات",
    approach: "نهج العمل",
    "contact.us": "اتصل بنا",
    "footer.disclaimer":
      "جميع الشعارات والعلامات التجارية مملوكة لأصحابها الشرعيين.",
    "hero.name": "Frontier Web Services:",
    "hero.tag": "تمكين النمو من خلال الابتكار",
    "hero.text":
      " نصمم تطبيقات ويب وجوال قوية ومستقبلية مصممة لتلبية الاحتياجات المحددة لصناعتك.  كذلك ندير إعلانات Facebook و Google Ads لدفع نموك بعد مرحلة الإطلاق. وللحفاظ على اتصال فريقك ، نقدم خدمات بريد إلكتروني تجارية آمنة وعالية الثقة مدعومة من Google Workspace.",
    "clients.text":
      "نحظى بثقة جامعات أمريكية رائدة وشركات بجميع الأحجام في الولايات المتحدة وخارجها ، بما في ذلك موريتانيا.",
    "features.title": "تحفيز الابتكار: التكنولوجيا المستخدمة",
    "features.body":
      "في Frontier Web Services ، نؤمن بالاستفادة من أفضل التقنيات لتقديم نتائج استثنائية لعملائنا. يتم اختيار أدوات التطوير الخاص بنا بعناية لضمان:",
    "features.box.1.title": "الأداء وقابلية التوسع:",
    "features.box.1.subtitle": "الحوسبة السحابية والذكاء الاصطناعي",
    "features.box.1.body":
      "نستفيد من قوة الذكاء الاصطناعي مع منصات السحابة الرائدة في الصناعة مثل AWS و GCP لتقديم حلول أعمال ذكية. يُمكّننا هذا من بناء تطبيقات ويب وجوال قابلة للتوسيع بشكل كبير ويمكنها التكيف بسلاسة مع احتياجاتك المتنامية.",
    "features.box.2.title": "الأمان والعمل الجماعي:",
    "features.box.2.subtitle": "Google Workspace",
    "features.box.2.body":
      "عزز العمل الجماعي لفريقك مع تكامل Google Workspace السلس. توفر هذه المجموعة الآمنة البريد الإلكتروني والتقويم ومشاركة المستندات، مما يُبقي الجميع على اتصال وإنتاجية. بالإضافة إلى ذلك، يعتبر أمن البيانات أولوية، مما يمنحك راحة البال أثناء تعاون فريقك بكفاءة.",
    "features.box.3.title": "أدوات تطوير حديثة:",
    "features.box.3.subtitle": "أدوات متطورة",
    "features.box.3.body":
      "نبقى في المقدمة من خلال الاستفادة من أحدث إطارات ومكتبات تطوير الويب والجوال. يضمن ذلك أن يتميز تطبيقك بواجهة حديثة سهلة الاستخدام وأداء استثنائي. تطبيقاتنا سهلة الوصول وتدعم تعدد اللغات بشكل افتراضي، مما يوفر تجربة مستخدم سلسة على مستوى العالم.",
    "stats.lightouse": "نتيجة لايت هاوس",
    "stats.happy.customers": "عملاء سعداء",
    "stats.uptime": "مضمونة التشغيل",
    "approach.title": "ركز على عملك",
    "approach.body":
      "من خلال الاستفادة من التقنيات المتقدمة ، نتولى التعقيدات الفنية خلف الكواليس. يمكنك التركيز على ما يهم أكثر - تنمية عملك وتحقيق أهدافك الاستراتيجية.",
    "contact.us.text":
      "يسعدنا دائمًا أن نسمع منك ، ولدينا أيضًا مكاتب خارجية في كل من الولايات المتحدة وموريتانيا.",
    "contact.us.address": "مكاتبنا:",
    "contact.us.address.line1": "شيكاغو، إلينوي",
    "contact.us.address.line2": "الولايات المتحدة الأمريكية",
    "contact.us.address2.line1": "نواكشوط",
    "contact.us.address2.line2": "الجمهورية الإسلامية الموريتانية",
    "contact.us.sent": "شكرًا لتواصلك معنا، سنرد عليك قريبًا.",
    "contact.name": "الاسم",
    "contact.email": "بريد إلكتروني",
    "contact.company": "شركة",
    "contact.phone": "هاتف",
    "contact.note": "أخبرنا عن مشروعك",
    "contact.required": "جميع الحقول مطلوبة",
    "contact.submit": "إرسال",
    "permission-denied": "صلاحيات غير كافية أو مفقودة.",
  },
  [Lang.en]: {
    home: "Home",
    "welcome.back": "Welome back! \n {{name}}",
    "home.title": "Welcome to Frontier Web Services",
    "layout.company": "Frontier Web Services",
    "layout.copyright": "© {{date}} Frontier Web Services",

    "errors.general": "Sorry, there was an error!",
    "errors.required.fields": "Please complete required fields!",
    save: "Save",
    back: "Back",
    download: "Download",
    delete: "Delete",

    cancel: "Cancel",
    "welcome.user": "Welcome {{displayName}}!",
    ok: "Ok",
    search: "Search",
    "no.result": "No results found, please try different keywords.",

    "show.more": "Show more",

    "back.to.document": "Back to document",
    "email.address": "Email Address",

    "last.name": "Last Name",
    "first.name": "First Name",
    "full.name": "Full name",

    NetworkError: "Sorry, there was a connection error, please try again.",

    "auth/network-request-failed": "Network request failed.",

    "coming.soon": "Coming Soon",

    logo: "Logo",
    "ERROR_auth/network-request-failed": "Network request failed",
    "password.required": "Password required.",
    refresh: "Refresh",
    "update.available": "A new app update is available",
    "update.available.desc":
      " Hit `Refresh` to get the latest update, or click `Cancel` to ignore this update.",

    active: "Active",

    inactive: "Inactive",
    documents: "Documents",
    "view.pdf": "Download",
    update: "Update",

    "error.code.404": "404",
    "error.message.404": "Oops, something went wrong.",
    "error.message.404.details": "Sorry, we couldn't find your page.",
    "common.back.to.home": "Back to Homepage",
    "error.message.403": "Forbidden",
    "error.message.403.details":
      "You don't have permission to access this resource.",
    "error.message.500": "Internal Server Error",
    "error.message.500.details":
      "The server encountered an unexpected error. Please try again later.",
    "release.date": "Release {{date}}",
    "tech.stack": "Technology Stack",
    stats: "Stats",
    approach: "Approach",
    "contact.us": "Contact Us",
    "footer.disclaimer":
      "All logos and trademarks are the property of their respective owners.",
    "hero.name": "Frontier Web Services:",
    "hero.tag": "Empowering Growth Through Innovation",
    "hero.text":
      "We engineer powerful, future-proof web and mobile applications designed to meet the specific needs of your industry. We even manage Facebook & Google Ads to fuel your growth beyond the launchpad. And to keep your team connected, we offer secure and reliable business email services powered by Google Workspace.",
    "clients.text":
      "Trusted by Leading US Universities, Businesses of All Sizes in the US and Beyond, Including Mauritania.",
    "features.title": "Powering Innovation: Our Technology Stack",
    "features.body":
      "At Frontier Web Services, we believe in leveraging the best technologies to deliver exceptional results for our clients. Our development stack is meticulously chosen to ensure:",
    "features.box.1.title": "Scalability & Performance:",
    "features.box.1.subtitle": "Cloud computing and AI",
    "features.box.1.body":
      "We combine the power of AI with industry-leading cloud platforms like AWS and GCP to deliver intelligent business solutions. This empowers us to build highly scalable web and mobile applications that seamlessly adapt to your growing needs.",
    "features.box.2.title": "Security & Collaboration:",
    "features.box.2.subtitle": "Google Workspace",
    "features.box.2.body":
      "Streamline your teamwork with seamless integration of Google Workspace. Stay connected and productive with real-time communication, centralized scheduling, and effortless document sharing.  Google Workspace also prioritizes data security, giving you peace of mind while your team collaborates efficiently.",
    "features.box.3.title": "Modern Development Tools:",
    "features.box.3.subtitle": "Cutting-Edge Tools",
    "features.box.3.body":
      "We stay ahead of the curve by leveraging the latest web and mobile development frameworks and libraries. This ensures your application features a modern, user-friendly interface and exceptional performance. Our apps are accessible and support internationalization by default, providing a seamless user experience worldwide.",
    "stats.lightouse": "Lighthouse Score",
    "stats.happy.customers": "Happy Customers",
    "stats.uptime": "Uptime Guaranteed",
    "approach.title": "Focus on Your Business",
    "approach.body":
      "By leveraging the advanced technologies, we take care of the technical complexities behind the scenes. You can focus on what matters most - growing your business and achieving your strategic goals.",
    "contact.us.text":
      "Allways happy to hear from you, we also have remote offices in both the US and Mauritania.",
    "contact.us.address": "Our Offices:",
    "contact.us.address.line1": "Chicago, IL",
    "contact.us.address.line2": "United States of America",
    "contact.us.address2.line1": "Nouakchott",
    "contact.us.address2.line2": "Islamic Republic of Mauritania",
    "contact.us.sent": "Thanks for reaching out, we will get back to you soon.",
    "contact.name": "Name",
    "contact.email": "Email",
    "contact.company": "Company",
    "contact.phone": "Phone",
    "contact.note": "Tell us about your project",
    "contact.required": "All field are required",
    "contact.submit": "Submit",
    "permission-denied": "Missing or insufficient permissions.",
  },
  [Lang.fr]: {
    home: "Accueil",
    "layout.company": "Frontier Web Services",
    "layout.copyright": "© {{date}} Frontier Web Services",

    "errors.general": "Désolé, il y a eu une erreur!",
    "errors.required.fields": "Veuillez compléter les informations requises!",
    save: "Sauvegarder",

    back: "Retour",
    download: "Télécharger",
    delete: "Supprimer",

    cancel: "Annuler",

    "welcome.user": "Bienvenue {{displayName}}!",
    ok: "Ok",
    search: "Rechercher",
    "no.result":
      "Aucun résultat trouvé, veuillez essayer différents mots clés.",
    "show.more": "Afficher plus",
    attachments: "Pièces jointes",

    "email.address": "Adresse e-mail",

    "last.name": "Nom de famille",
    "first.name": "Prénom",
    "full.name": "Nom et prénom",
    "confirm.sign.up": "Confirmer l'inscription",

    NetworkError:
      "Désolé, il y a eu une erreur de connexion, veuillez réessayer.",
    "try.again": "Réessayer",

    "see.more": "Voir plus",

    logo: "Logo",
    "ERROR_auth/network-request-failed": "Erreur de réseau",

    account: "Compte",
    settings: "Paramètres",

    refresh: "Actualiser",
    "update.available":
      "Une nouvelle mise à jour de l'application est disponible",
    "update.available.desc":
      " Appuyez sur `Actualiser` pour obtenir la dernière mise à jour, ou cliquez sur `Annuler` pour ignorer cette mise à jour.",

    active: "Actif",

    inactive: "Inactif",
    documents: "Documents",
    "view.pdf": "Télécharger",
    update: "Mettre à jour",
    "error.code.404": "404",
    "error.message.404": "Oups, une erreur est survenue.",
    "error.message.404.details":
      "Désolé, nous n'avons pas pu trouver votre page.",
    "common.back.to.home": "Retour à la page d'accueil",
    "error.message.403": "Interdit",
    "error.message.403.details":
      "Vous n'avez pas la permission d'accéder à cette ressource.",
    "error.message.500": "Erreur interne du serveur",
    "error.message.500.details":
      "Le serveur a rencontré une erreur inattendue. Veuillez réessayer plus tard.",
    "release.date": "Publication {{date}}",
    "tech.stack": "Technologies Utilisées",
    stats: "Statistiques",
    approach: "Approche",
    "contact.us": "Contactez-Nous",
    "footer.disclaimer":
      "Tous les logos et marques de commerce sont la propriété de leurs propriétaires respectifs.",
    "hero.name": "Frontier Web Services :",
    "hero.tag": "Favoriser la Croissance par l'Innovation",
    "hero.text":
      "Nous concevons des applications web et mobiles puissantes et pérennes, conçues pour répondre aux besoins spécifiques de votre industrie. Nous gérons même les annonces Facebook et Google pour alimenter votre croissance au-delà du lancement. Et pour maintenir votre équipe connectée, nous offrons des services de messagerie d'affaires sécurisés et fiables fournis par Google Workspace.",
    "clients.text":
      "Fait confiance par les principales universités américaines, les entreprises de toutes tailles aux États-Unis et au-delà, y compris la Mauritanie.",
    "features.title": "Favoriser l'Innovation : Nos Technologies Utilisées",
    "features.body":
      "Chez Frontier Web Services, nous croyons en l'utilisation des meilleures technologies pour offrir des résultats exceptionnels à nos clients. Notre stack de développement est minutieusement choisie pour assurer :",
    "features.box.1.title": "Évolutivité et Performance :",
    "features.box.1.subtitle": "Informatique en nuage et IA",
    "features.box.1.body":
      "Nous combinons la puissance de l'IA aux plateformes cloud leaders du secteur, telles que AWS et GCP, pour fournir des solutions d'entreprise intelligentes. Cela nous permet de créer des applications Web et mobiles hautement évolutives qui s'adaptent parfaitement à vos besoins croissants.",
    "features.box.2.title": "Sécurité et Collaboration :",
    "features.box.2.subtitle": "Google Workspace",
    "features.box.2.body":
      "Optimisez la collaboration d'équipe grâce à l'intégration transparente de Google Workspace. Restez connecté et productif grâce à la communication en temps réel, à la planification centralisée et au partage de documents sans effort. Google Workspace accorde également la priorité à la sécurité des données, vous offrant une tranquillité d'esprit pendant que votre équipe collabore efficacement.",
    "features.box.3.title": "Solutions de Dev Innovantes :",
    "features.box.3.subtitle": "Outils de Pointe",
    "features.box.3.body":
      "Nous gardons une longueur d'avance en exploitant les derniers cadres et bibliothèques de développement Web et mobile. Cela garantit à votre application une interface moderne et conviviale, ainsi que des performances exceptionnelles. Nos applications sont accessibles et prennent en charge l'internationalisation par défaut, offrant ainsi une expérience utilisateur fluide dans le monde entier.",
    "stats.lightouse": "Score Lighthouse",
    "stats.happy.customers": "Clients Satisfaits",
    "stats.uptime": "Temps de Fonctionnement Garanti",
    "approach.title": "Concentrez-vous sur Votre Entreprise",
    "approach.body":
      "En tirant parti des technologies avancées, nous nous occupons des complexités techniques en coulisses. Vous pouvez vous concentrer sur ce qui compte le plus - développer votre entreprise et atteindre vos objectifs stratégiques.",
    "contact.us.text":
      "Toujours heureux de vous entendre, nous avons également des bureaux distants aux États-Unis et en Mauritanie.",
    "contact.us.address": "Nos Bureaux :",
    "contact.us.address.line1": "Chicago, Illinois",
    "contact.us.address.line2": "États-Unis d'Amérique",
    "contact.us.address2.line1": "Nouakchott",
    "contact.us.address2.line2": "République islamique de Mauritanie",
    "contact.us.sent":
      "Merci de nous avoir contactés, nous vous répondrons bientôt.",
    "contact.name": "Nom",
    "contact.email": "Email",
    "contact.company": "Entreprise",
    "contact.phone": "Téléphone",
    "contact.note": "Parlez-nous de votre projet",
    "contact.required": "Tous les champs sont requis",
    "contact.submit": "Envoyer",
    "permission-denied": "Permissions manquantes ou insuffisantes.",
  },
};
