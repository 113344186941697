import { QueryClient } from "react-query";
import mrFlag from "~assets/flags/mr_flag.svg";
import usFlag from "~assets/flags/ic_flag_us.svg";
import frFlag from "~assets/flags/ic_flag_fr.svg";
// API
// ----------------------------------------------------------------------

export const HOST_API = import.meta.env.VITE_HOST_API_KEY || "";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const FIREBASE_CONFIG = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export const RECAPTCHA_SITE_KEY = import.meta.env.VITE_FIREBASE_RECAPTCHA_KEY;

// LAYOUT
// ----------------------------------------------------------------------

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  { id: 1, name: "العربية", value: "ar", icon: mrFlag },
  { id: 2, name: "Français", value: "fr-FR", icon: frFlag },
  { id: 3, name: "English", value: "en-US", icon: usFlag },
];

export const defaultLang = allLangs[1]; // English

// === pagination
export const ROWS_PER_PAGE_OPTIONS = [10, 20, 30];

//======= RQ client
// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

// CSS

export const inputCssClassString =
  "block w-full rounded-lg border-gray-200 px-4 py-3 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600";

export const linkCSSClassString =
  "text-blue-600 hover:underline hover:decoration-blue-600";

// Images
export const genericBusinessLogos: Record<string, string> = {
  "1": "/generic-business-photos/1.png",
  "2": "/generic-business-photos/2.png",
  "3": "/generic-business-photos/3.png",
  "4": "/generic-business-photos/4.png",
} as const;
