import { createContext, ReactNode, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import type { User as FirebaseUser } from "firebase/auth";
// @types
import { FirebaseContextType } from "~types/data";
//
import { AUTH } from "~backend/firebase";

// ----------------------------------------------------------------------

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onAuthStateChanged(AUTH, (_user) => {
        setUser(_user);
        setIsInitialized(true);
      }),
    [],
  );

  return (
    <AuthContext.Provider
      value={{
        isInitialized,
        user,
        profile: null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
