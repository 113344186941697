import { ElementType, Suspense } from "react";
// components
import LoadingScreen from "~components/LoadingScreen";

const Loadable = (Component: ElementType) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function loadable(props: any) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
