import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./contexts/FirebaseContext";
import { routes } from "./routes";
// i18n
import "~i18n/i18n";

import PwaUpdateBanner from "~components/PwaUpdateBanner";

//import App from './App.tsx'
//import Main from './layouts/Main.tsx';
import "./index.css";

const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
      <PwaUpdateBanner />
    </HelmetProvider>
  </React.StrictMode>,
);
