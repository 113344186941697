import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    viewBox="0 0 300 100"
    direction="ltr"
    {...props}
  >
    <text
      fill="currentColor"
      strokeWidth={0}
      dx={0}
      dy={0}
      fontSize={100}
      fontFamily='"Roboto"'
      fontWeight={500}
      transform="translate(95.365 84.131)"
    >
      <tspan y={0}>FWS</tspan>
    </text>
    <path
      fill="#61873f"
      d="m67.12 71.619-39.092 5.426-17.24-35.502 28.436-27.368L74.04 32.763 67.12 71.62z"
    />
  </svg>
);
export default SvgComponent;
