import { useEffect } from "react";
import { QueryClientProvider } from "react-query";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { queryClient } from "~config";
import "./App.css";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.dir = i18n.language === "ar" ? "rtl" : "ltr";
  }, [i18n.language]);
  return (
    <QueryClientProvider client={queryClient}>
      <Outlet />
      <Toaster />
    </QueryClientProvider>
  );
}

export default App;
