import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "./Loadable";

// layouts
import MainLayout from "~layouts/Main";

// Root
import App from "~App";

// ----------------------------------------------------------------------

// MAIN
import HomePage from "~routes/loadables/Home";

const Page500 = Loadable(lazy(() => import("~pages/Page500")));
const Page403 = Loadable(lazy(() => import("~pages/Page403")));
const Page404 = Loadable(lazy(() => import("~pages/Page404")));

export const routes = [
  {
    element: <App />,
    errorElement: <Page500 />,
    children: [
      // Main Routes
      {
        path: "*",
        children: [
          { path: "500", element: <Page500 /> },
          { path: "404", element: <Page404 /> },
          { path: "403", element: <Page403 /> },
          { path: "*", element: <Navigate to="/404" replace /> },
        ],
      },
      {
        path: "/",
        element: <MainLayout />,
        children: [
          { element: <HomePage />, index: true },
          // account
        ],
      },
    ],
  },
];
